// Your custom styles
@import "custom/loader";
@import "custom/intro";
@import "custom/navbar";
@import "custom/pricing-card";
// @import "custom/fixes";

.h-66 {
    height: 66%!important;
}

#kezeles,
#szolgaltatas,
#berlet {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .4;
        z-index: -1;
        background-image: url(../img/background-hexagon.png);
        background-repeat: repeat;
    }
}

.skew-r-t {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0px;
    background: linear-gradient(to right top, $primary-color 49%, transparent 50%);
    z-index: 1;
}

.skew-r-b {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0px;
    background: linear-gradient(to right bottom, $primary-color 49%, transparent 50%);
    z-index: 1;
}

.skew-l-t {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0px;
    background: linear-gradient(to left top, $primary-color 49%, transparent 50%);
    z-index: 1;
}

.skew-l-b {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0px;
    background: linear-gradient(to left bottom, $primary-color 49%, transparent 50%);
    z-index: 1;
}
