// Pricing cards
.card {
    &.card-image {
        background-size: cover;
        background-position: center;
        width: 100%;
        [class*=rgba-] {
            border-radius: .25rem;
        }
    }
    .card-body {
        h1, h2, h3, h4, h5, h6 {
            font-weight: 900;
        }
    }

}
.pricing-card {
    text-align: center;
    .price {
        position: relative;
        padding-top: 3.94rem;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    .striped {
        padding: 1rem;
        li {
            border-bottom: 1px solid rgba(238,238,238,.298039);
            margin-bottom: 1rem;
        }
    }
}
