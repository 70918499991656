@include media-breakpoint-down(xl) {
    html,
    body.home,
    #intro {
        height: 100%;
    }
    body.home header {
        height: 66%;
    }

    #intro {
        background: url("../img/parallax.jpg") no-repeat center center fixed;
        background-size: cover;
        .container-fluid {
            margin-top: 80px;
            padding-left: 0;
            padding-right: 0;
            .p-lead,
            .p-sublead {
                padding-top: 5px;
                padding-right: 10px;
                padding-bottom: 2px;
                padding-left: 10%;
            }
            .m-lead,
            .m-sublead {
                margin-top: 0;
                margin-right: 0;
                margin-bottom: 0;
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #intro {
        .container-fluid {
            .p-lead,
            .p-sublead {
                padding-top: 5px;
                padding-right: 10px;
                padding-bottom: 2px;
                padding-left: 10%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #intro {
        .container-fluid {
            .p-lead,
            .p-sublead {
                padding-top: 5px;
                padding-right: 10px;
                padding-bottom: 2px;
                padding-left: 5%;
            }
            .p-lead {
                font-size: 26px;
            }
            .p-sublead {
                font-size: 12px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    #intro {
        .container-fluid {
            .p-lead,
            .p-sublead {
                padding-top: 5px;
                padding-right: 10px;
                padding-bottom: 2px;
                padding-left: 10px;
                margin-left: 20px;
                margin-right: 20px;
            }
            .p-lead {
                font-size: 24px;
            }
            .p-sublead {
                font-size: 11px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    #intro {
        .container-fluid {
            .p-lead,
            .p-sublead {
                padding-top: 5px;
                padding-right: 10px;
                padding-bottom: 2px;
                padding-left: 10px;
                margin-left: 20px;
                margin-right: 20px;
            }
            .p-lead {
                font-size: 16px;
            }
            .p-sublead {
                font-size: 11px;
            }
        }
    }
}
