// /* Navbar color */
// body.home {
//     .navbar {
//         box-shadow: none;
//     }
//     .top-nav-collapse {
//         background-color: $primary-color;
//     }
// }
// @media (min-width: 600px) {
//     body.home {
//         .navbar.scrolling-navbar.top-nav-collapse {
//             box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
//         }
//     }
// }
// @media (max-width: 768px) {
//     body.home {
//         .navbar:not(.top-nav-collapse) {
//             background-color: $primary-color;
//         }
//     }
// }
// @media (min-width: 800px) and (max-width: 850px) {
//     body.home {
//         .navbar:not(.top-nav-collapse) {
//             background-color: $primary-color;
//         }
//     }
// }


.navbar {
    padding: 0 1rem;
    .navbar-nav {
        position: relative;
        .nav-active, nav.navbar {
            transition: all ease-in-out 0.3s;
            -moz-transition: all ease-in-out 0.3s;
            -ms-transition: all ease-in-out 0.3s;
            -o-transition: all ease-in-out 0.3s;
            -webkit-transition: all ease-in-out 0.3s;
        }
        .nav-active {
            bottom: 0;
            left: 0;
            width: 140px;
            height: 3px;
            position: absolute;
            background: $primary-color-dark;
        }
    }
}
.nav-link {
    padding: .75rem 1rem;
}

@include media-breakpoint-down(md) {
    .navbar {
        .navbar-nav {
            .nav-active {
                display: none;
            }
        }
    }
}